import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import FadeInSection from "./FadeInSection";

const isHorizontal = window.innerWidth < 600;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  if (isHorizontal) {
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  } else {
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  if (isHorizontal) {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`
    };
  } else {
    return {
      id: `vertical-tab-${index}`
    };
  }
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: "theme.palette.background.paper",
    display: "flex",
    height: 300
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`
  }
}));

const JobList = () => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const experienceItems = {
    TupplTechnologies: {
      jobTitle: "Software Developer @",
      duration: "Aug 2020 - Dec 2022",
      desc: [
        "Designing and implemented applications using Java and various frameworks like Spring Boot, Spring MVC, and Hibernate.",
        "Developing RESTful APIs and used Spring Boot for consuming and producing JSON/XML data from endpoints.",
        "Used Spring DAO module for performing CRUD operations on the database and implemented logging in the application using Spring Framework AOP module to know the application status.",
        "Created automation and deployment templated for relational and NoSQL databases including MYSQL, and MSSQL in AWS.",
        "Involved in designing and developing the application using ReactJS, Bootstrap, HTML5, and CSS3.",
        "Used Jenkins pipeline to drive all microservices builds out to the Docker registry and then deployed to Kubernetes, created pods, and managed using Kubernetes.",
        "Deployed REST-Web Services Oriented Architecture (SOA) consisting of the integration tier with Web services external to organization, middle tier with Spring Application Framework."
      ]
    },

    LiveStockCity: {
      jobTitle: "Software Engineer Intern @",
      duration: "Jan 2020 - Jul 2020",
      desc: [
        "Collaborated with senior software engineers to develop RESTful APIs using Spring Boot.",
        "Worked on the development of a new feature that increased the performance of the application by 30%.",
        "Contributed to the implementation of a microservices architecture using Kubernetes and Docker.",
        "Assisted in creating automation and deployment templates for MYSQL in AWS."
        
      ]
    },

   

    EastCentralUniversity: {
      jobTitle: "Data Science Student Researcher @",
      duration: "Aug 2019 - Dec 2019",
      desc: [
        "Created deep learning for image and speech recognition with an average efficiency of ~75%.",
        "Contributed to the implementation of natural language processing algorithms for sentiment analysis and topic modeling.",
        "Used Python, TensorFlow, NLTK for model development and Jupyter Notebook for experimentation and analysis."
      ]
      
    },

    AIDevNepal: {
      jobTitle: "Artificial Intelligence Intern @",
      duration: "JAN 2019 - JUN 2019",
      desc: [
        "Developed Digit Recognizer using Python, NumPy, Pandas, Matplotlib, and NLTK and deployed on Heroku.",
        "Completed 14 workshops and hands-on labs on Machine Learning topics (Scikit-Learn, Linear Regression, Random Forest, NLP, KNN, Classification, Neural Networks, etc.)"
      ]
    },
    TribhuvanUniversity: {
      jobTitle: "Participant at National Civil Engineering Competition@",
      duration: "FEB 2018 - MAR 2018",
      desc: [
        "Received 1st position at the country's highest-ranked engineering institution for building an Innovative Smart City prototype for the Kathmandu Metropolitan City, Nepal. ",
        "Built smart system which comprises of CO2 conversion method, sewages management, automated gate and water management using Arduino, smart airports, movable roads inside cities, hydraulic bridges."
      ]
    },
    
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <Tabs
        orientation={!isHorizontal ? "vertical" : null}
        variant={isHorizontal ? "fullWidth" : "scrollable"}
        value={value}
        onChange={handleChange}
        className={classes.tabs}
      >
        {Object.keys(experienceItems).map((key, i) => (
          <Tab label={isHorizontal ? `0${i}.` : key} {...a11yProps(i)} />
        ))}
      </Tabs>
      {Object.keys(experienceItems).map((key, i) => (
        <TabPanel value={value} index={i}>
          <span className="joblist-job-title">
            {experienceItems[key]["jobTitle"] + " "}
          </span>
          <span className="joblist-job-company">{key}</span>
          <div className="joblist-duration">
            {experienceItems[key]["duration"]}
          </div>
          <ul className="job-description">
            {experienceItems[key]["desc"].map(function (descItem, i) {
              return (
                <FadeInSection delay={`${i + 1}00ms`}>
                  <li key={i}>{descItem}</li>
                </FadeInSection>
              );
            })}
          </ul>
        </TabPanel>
      ))}
    </div>
  );
};

export default JobList;
