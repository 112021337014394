import React from "react";
import "../styles/Projects.css";
import FolderOpenRoundedIcon from "@material-ui/icons/FolderOpenRounded";
import GitHubIcon from "@material-ui/icons/GitHub";
import OpenInBrowserIcon from "@material-ui/icons/OpenInBrowser";
import FadeInSection from "./FadeInSection";

class Projects extends React.Component {
  constructor() {
    super();
    this.state = {
      expanded: true,
      activeKey: "1"
    };
    this.handleSelect = this.handleSelect.bind(this);
  }
  handleSelect(eventKey) {
    this.setState({
      activeKey: eventKey
    });
  }
  render() {
    const projects = {
      "Java-based e-commerce web application": {
        desc:
          "Developed a Java-based e-commerce web application that allows users to browse and purchase products. Implemented features such as user authentication, shopping cart, and order tracking using Spring Boot and Hibernate.",
        techStack: "Java, React.js, Bootstrap, HTML5, REST API",
        open: ""
       
      },
      "RESTful API for a blog website": {
        desc:
          " Built a RESTful API using Spring Boot that allows users to create, read, update, and delete blog posts. Used MYSQL as the database and implemented logging using Spring Framework AOP module. Tested the API uisng Junit and Mockito.",
        techStack: "MYSQL, Spring, REST API",
        open: ""
       
      },
      "Chatbot for customer service": {
        desc:
          "Developed a chatbot using natural language processing algorithms for customer service. Used NLTK for text processing and classfication. Deployed the chatbot using Docker and AWS.",
        techStack: "Python,NLTK, Pandas, NumPy, Scikit-Learn",
        open: ""
      },
      "Movie recommender system": {
        desc:
          "A system built using TMDB API and NLTK to suggest movies recommendation for its users.",
        techStack: "Python, Pandas, NumPy, Scikit-Learn, NLTK, Steamlit, Pickle, Tkinter, TMDB API",
        link: "https://github.com/roybibek/Movie-recommender-system",
        open: ""
      },
      "Home Finder": {
        desc:
          "A real state web application built to find accommodation services for its users.",
        techStack: "Next.js, Chakra UI, RAPID API",
        link: "https://github.com/roybibek/Home-finder",
        open: ""
      },
      "CoronaVirus Tracker": {
        desc:
          "A Coronavirus tracker web application is built to track real-time coronavirus cases globally.",
        techStack: "Java, Spring Framework,",
        link: "https://github.com/roybibek/Coronavirus-Tracker",
        open: ""
      },
      "Weather Meter": {
        desc:
          "An Android app consists of weather API to predict weather, temperature of certain location.",
        techStack: "Java, Weather API, Android Studio, Location API",
        link: "https://github.com/roybibek/WeatherMeter",
        open: ""
      }
    };

    return (
      <div id="projects">
        <div className="section-header ">
          <span className="section-title">/ software-creations</span>
        </div>

        <div className="project-container">
          <ul className="projects-grid">
            {Object.keys(projects).map((key, i) => (
              <FadeInSection delay={`${i + 1}00ms`}>
                <li className="projects-card">
                  <div className="card-header">
                    <div className="folder-icon">
                      <FolderOpenRoundedIcon
                        style={{ fontSize: 35 }}
                      ></FolderOpenRoundedIcon>
                    </div>
                    <span className="external-links">
                      <a className="github-icon" href={projects[key]["link"]}>
                        <GitHubIcon
                          style={{
                            fontSize: 20,
                            color: "var(--lightest-slate)"
                          }}
                        ></GitHubIcon>
                      </a>
                      {projects[key]["open"] && (
                        <a className="open-icon" href={projects[key]["open"]}>
                          <OpenInBrowserIcon
                            style={{
                              fontSize: 25,
                              color: "var(--lightest-slate)"
                            }}
                          ></OpenInBrowserIcon>
                        </a>
                      )}
                    </span>
                  </div>

                  <div className="card-title">{key}</div>
                  <div className="card-desc">{projects[key]["desc"]}</div>
                  <div className="card-tech">{projects[key]["techStack"]}</div>
                </li>
              </FadeInSection>
            ))}
          </ul>
        </div>
      </div>
    );
  }
}

export default Projects;
