import React from "react";
import "../styles/About.css";
import FadeInSection from "./FadeInSection";

class About extends React.Component {
  constructor() {
    super();
    this.state = {
      expanded: true,
      activeKey: "1"
    };
    this.handleSelect = this.handleSelect.bind(this);
  }
  handleSelect(eventKey) {
    this.setState({
      activeKey: eventKey
    });
  }
  render() {
    const one = (
      <p>
        I am currently a senior(fourth year) <b>Computer Science major</b> at{" "}
        <b> East Central University </b>, graduating this semester <b>, </b>with a focus in Software Systems and
        Machine Learning. I was also working as a Software Engineer at{" "}
        <a href="https://www.tuppl.com/">Tuppl Technologies</a> on contract 
        <b></b>.
      </p>
    );
    const two = (
      <p>
        I'm a service-focused and creative Software Developer with extensive experience in Java and frameworks like Spring Boot, Spring MVC, and Hibernate. I have experienced in developing RESTful APIs, implementing CRUD operations, and logging using Spring Framework. I am skilled in creating automation and deployment templates for databases in AWS and proficient in designing user-friendly web applications using ReactJS, Bootstrap, HTML5, and CSS3. I have also understanding of microservices architecture, utilizing Jenkins, Docker, and Kubernetes for efficient application deployment. I have experience in deploying REST-Web Services Oriented Architecture (SOA) and have a passion for leveraging deep learning models and natural language processing algorithms using Python, TensorFlow, NLTK, and Jupyter Notebook. I am known by my excellent communication skills, creative thinking, and execution excellence in delivering succsessful projects.
      </p>
    );
    const three = (
      <p>
        <b>Want to chat?</b> Shoot me a message at{" "}
        <a href="mailto:roybibek2021@gmail.com">
          roybibek2021@gmail.com
        </a>{" "}
        and let's talk.
      </p>
    );
    const desc_items = [one, two];

    const tech_stack = [
      "Java",
      "Python/JavaScript",
      "Spring MVC",
      "Spring Boot",
      "MYSQL/MSSQL",
      "AWS",
      "Jenkins/Spring Integration",
      "TensorFlow/NTLK"
    ];

    

    return (
      <div id="about">
        <FadeInSection>
          <div className="section-header ">
            <span className="section-title">/ about me</span>
          </div>
          <div className="about-content">
            <div className="about-description">
              {desc_items}
              {"Here are some technologies I have been working with:"}
              <ul className="tech-stack">
                {tech_stack.map(function (tech_item, i) {
                  return (
                    <FadeInSection delay={`${i + 1}00ms`}>
                      <li>{tech_item}</li>
                    </FadeInSection>
                  );
                })}
              </ul>
            </div>      
          </div>
        </FadeInSection>
      </div>
    );
  }
}

export default About;
